// Import the functions you need from the SDKs you need
import history from "../history"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries'

import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'

import axiosInstance from "../axios";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA1QkneHs6wS6JgdkimDrTC6jbErRLWm6E",
  authDomain: "virtual-psychologist-339907.firebaseapp.com",
  databaseURL: "https://virtual-psychologist-339907-default-rtdb.firebaseio.com",
  projectId: "virtual-psychologist-339907",
  storageBucket: "virtual-psychologist-339907.appspot.com",
  messagingSenderId: "658092414725",
  appId: "1:658092414725:web:fa34aa1a5aad8c990bc96c",
  measurementId: "G-YGB4VFX4M1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth=getAuth(app)
export const provider=new GoogleAuthProvider();

export const signInWithGoogle =()=>{
    signInWithPopup (auth, provider).then((result)=>{
        console.log(result)
        const UserVSgoogle={
          "user_first_name": result._tokenResponse.firstName,
          "user_last_name": result._tokenResponse.lastName,
          "user_email": result._tokenResponse.email,
          "user_local_id":result.user.uid,
          "user_session":"Session 1"
        }
        // localStorage.setItem("userVs", JSON.stringify(UserVSgoogle));

        // try {
        //   debugger;
        //   axiosInstance
        //     .post(`/user_sign_in_gmail`, UserVSgoogle)
        //     .then(function (response) {
        //       console.log("goodbye res", response);
    
        //       if (
        //         typeof response.data.message !== "undefined" &&
        //         response.data.message === "INVALID_ID_TOKEN"
        //       ) {
        //         try {
        //           // RefreshToken().then((res) => {
        //           //   if (
        //           //     typeof res.data.message !== "undefined" &&
        //           //     res.data.message === "TOKEN_REFRESHED"
        //           //   ) {
        //           //     users.id_token = res.data.data[0].new_id_token;
        //           //     localStorage.setItem("userVs", JSON.stringify(users));
        //           //     sendChatListToChatBot();
        //           //   }
        //           // });
        //         } catch (e) {
        //           console.log(e);
        //         }
        //       } else if (response?.status === 200) {
        //         // sessionExpire(true);
        //         // CHAT_LIST = [];
        //         // sessionEndedModalRef.current.show();
        //       }
        //     })
        //     .catch(function (error) {
        //       console.log(error);
        //     });
        // } catch (error) {
        //   console.log("goodbye error", error);
        // }



        history.push("/Chat");
        console.log("Data is",UserVSgoogle)
}).catch(error => console.log(error))
    
}