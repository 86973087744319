import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactAudioPlayer from 'react-audio-player';
import Language from "../../Language";

const TextToSpeech = ({
    isSpeech, 
    setisSpeech,
    setisSpeaking,
    stopVideo,
    playVideo,
    textToSpeak,
    isSpeaking,
    selectedLanguage,
    selectedAvatar
}) => {

  const [source, setSource] = useState(null);
  const apiKey = "AIzaSyDdWPhP-bgZYurw7dcGM01Wfyq3e1EuKdI";
  
  const textToSpeech = axios.create({
    baseURL: 'https://texttospeech.googleapis.com',
    params: { key: apiKey },
  });

  useEffect(() => {

    if (isSpeech && textToSpeak !== "") {
        handlePlay();
    }

  }, [isSpeech,textToSpeak]);

  // Event handlers
  const handlePlay = () => {
      
    let LanguageObj = Language.find(x=> x.language === selectedLanguage)
    let gender = selectedAvatar.split("_");

      textToSpeech
      .post('/v1beta1/text:synthesize', {
        "audioConfig": {
          "audioEncoding": "MP3",
          "pitch": 1,
          "speakingRate": 1
        },
        "input": {
          "text": textToSpeak,
        },
        "voice": {
          "languageCode": LanguageObj.language,
          "name": gender[0] === "1" ? LanguageObj.male : LanguageObj.female,
        }
    })
    .then((response) => {
        const audioData = response.data.audioContent;
        const audioUrl = `data:audio/mp3;base64,${audioData}`;
        setSource(audioUrl);
        setisSpeaking(true);
        playVideo();
        console.log('Audio started playing');
      });
  };

  const handlePause = () => {
    setisSpeaking(false);
    stopVideo();
    console.log('Audio paused');
  };

  const handleEnded = () => {
    setisSpeaking(false);
    stopVideo();
    console.log('Audio playback finished');
  };

  return (
    <div>
      {isSpeaking && <ReactAudioPlayer
        src={source} 
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        controls
        autoPlay
        style={{opacity : "0"}}
      />}
    </div>
  );
};

export default TextToSpeech;
