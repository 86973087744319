import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/humsafarlogo.png"
import "./style.css"
import axios from "axios"
import { baseURL } from "../../config.js"
import history from "../../history.js"
import Lottie from 'react-lottie';
import background from '../../assets/animation/background.json'
import { useLocation } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import history from "../../history";

const AccountVerification = () => {
    const location = useLocation();
    const [values, setvalues] = useState({})
    const [isActive, setisActive] = useState(true)
    const [timeLeft, settimeLeft] = useState("")

    let timerOn = true;


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: background,
        speed: 1,
        rendererSettings: {
            //   preserveAspectRatio: 'xMidYMid slice'
        }
    };

    
function timer(remaining) {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;
    
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    settimeLeft(m + ':' + s);
    remaining -= 1;
    
    if(remaining >= 0 && timerOn) {
      setTimeout(function() {
          timer(remaining);
      }, 1000);
      return;
    }
  
    if(!timerOn) {
      // Do validate stuff here
      return;
    }
    
    // Do timeout stuff here
    // alert('Timeout for otp');
    setisActive(false)
  }


    const SendCode = () => {
        debugger

        axios.post(`${baseURL}resend_email_verification`, values)
            .then(function (response) {
                debugger

                if (response.data.message === "VERIFICATION_EMAIL_SENT") {
                    setisActive(true)
                    timer(120);
                    // seterror("Email address already exists.")
                }
                else {
                    history.push({
                        pathname: './AccountVerification',
                        state: { email: values.email, password: values.password }
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

 const toLogin=()=>{
    // history.push("/Chat");
    history.push("/Login")
 }

    useEffect(() => {
        debugger
        var account = location.state
        if (typeof account !== 'undefined') {
            setvalues(account);
            timer(120);
            // console.log(email,password);
        }


    }, [location])

    const btnStyle = {
        // display: "inline-block",
        // gridGap: "10px",
        // marginTop: "20px",
        // marginLeft: "20px",
        // marginBottom: "20px"
        // marginRight:"2px"
        // backgroundColor:"#429EFA",
        // color:'#FFFFFF',
        // fontWeight: 'normal',
        // padding:"10px 30px 10px 30px"
        padding:"20px 40px 20px 40px"
    };

    return (
        <section>
            {/* <Lottie options={defaultOptions}
                    // height={'8vh'}
                    // width={'8vh'} 
                    isPaused={false}
            /> */}

            <div className="row m-0">
                <div className="col-12 card-centered">
                    <div className="d-flex flex-column align-items-center justify-content-center p-5">
                        <div className="card-Logo my-3"><img src={Logo} /></div>
                        <div className="card-body text-center">
                            <h5 className="card-title">Confirm your email address</h5>
                            <p className="card-text">We sent a confirmation to email to:</p>
                            <h6 className="card-text">{values.email}</h6>
                            <p className="card-text">Check your email and click on the confirmation link to continue.</p>
                            <p className="card-text">Note: Please check your spam and promotion folders, as some of our
            emails end up there for some reason!</p>
                            <button disabled={isActive} onClick={SendCode} className="btn btn-link mt-3" id="resend-btn">Resend email</button>
                            {isActive && 
                            <div className="resend-counter">Resend email in {timeLeft}</div>
                            }
                            <div>
                            {/* <button onClick={toLogin} className="btn btn-link mt-3"  id="resend-btn">Login</button> */}
                            <h3 className="new-account" style={btnStyle}><a onClick={()=>history.push("/Login")}>Sign in</a></h3>
                            {/* <button disabled={isActive} onClick={SendCode} className="btn btn-link mt-3" id="resend-btn">Faceid</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AccountVerification