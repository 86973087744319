import Main from "./components/chatModule/Main"
import Nolayout  from "./Layout/Nolayout";
import { Redirect } from 'react-router-dom'


export default [
     {
        path: "/",
        exact: true,
        layout: Nolayout,
        component: () => <Redirect to="/Chat" />
      },
 
      {
        path: "/Chat",
        layout: Nolayout,
        component: Main
      },

    
    ];
    