
import { APIKEY , translateURL} from "../config"
import axios from "axios";

const translateService = {

    translate: async (text, targetLanguage) => {
        console.log(targetLanguage)
        return await axios.post(translateURL, null, {
            params: {
                q: text,
                target: targetLanguage,
                key: APIKEY,
            },
        });
    },

}

export default translateService;
