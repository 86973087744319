import React, { forwardRef, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import MaleAvatars from "../../Avatar/Male";
import FemaleAvatars from "../../Avatar/Female";
import LanguageList from "../../Language";
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

const LanguageModal = (props, ref) => {

    let { openLanguage, setopenLanguage, selectedLanguage, setselectedLanguage } = props;
    const [hasRegion, setHasRegion] = useState(false);
    const [Region, setRegion] = useState(null);

    const handleSelected = (value) => {
        setselectedLanguage(value);
    };

    useEffect(() => {
        let langObj = LanguageList.find(x => x.language === selectedLanguage)
        if (langObj && langObj.hasRegion) {
            setHasRegion(langObj.hasRegion)
            setRegion(langObj.region)
        }

    }, [selectedLanguage])

    return (
        <Dialog fullWidth={true}
            maxWidth={"sm"}
            open={openLanguage}
            onClose={() => setopenLanguage(false)}
            className="avatar-dialog"
        >
            <DialogTitle>Select Language</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingTop: "15px" }}>
                    {LanguageList.map((language, index) => {
                        return (
                            !language.hasRegion &&
                            <Grid key={index} item xs={6} sm={4} md={4} className="language-main">
                                <Tooltip title={language.functions} placement="top" arrow>
                                    <div className={language.language === selectedLanguage ? "selected language-items" : "language-items"} onClick={() => handleSelected(language.language)}>
                                        <img src={language.flag} />
                                        <span className="language-name">{language.title}</span>
                                    </div>
                                </Tooltip>
                            </Grid>
                        )
                    })}
                </Grid>

                <p style={{ marginTop: "12px" }}>Regional Language</p>

                <Grid container spacing={2} style={{ paddingTop: "15px" }}>
                    {LanguageList.map((language, index) => {
                        return (
                            language.hasRegion &&
                            <Grid key={index} item xs={6} sm={4} md={4} className="language-main">
                                <Tooltip title={language.functions} placement="top" arrow>
                                    <div className={language.language === selectedLanguage ? "selected language-items" : "language-items"} onClick={() => handleSelected(language.language)}>
                                        <img src={language.flag} />
                                        <span className="language-name">{language.title}</span>
                                    </div>
                                </Tooltip>
                            </Grid>
                        )
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setopenLanguage(false)}>Done</Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyle = createUseStyles({
    // Your styles here
});

export default forwardRef(LanguageModal);
