import moment from "moment";
import Language from "../Language";
import translateService from "../Service/translate";

const decoder = require('he');

const jsUtils = {

    getDateTime: () => {
        const date = new Date();
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };

        return date.toLocaleString(undefined, options);
    },
    checkTimeIsRemaining: (startTime) => {
        var endTime = moment(new Date().getTime());
        var duration = moment.duration(endTime.diff(startTime));
        var minutes = parseInt(duration.asMinutes()) % 60;

        if (minutes > 9) {
            return false;
        }
        return true;
    },
    translateText: async (text, isUser,selectedLanguage) => {

    let LanguageObj = Language.find(x=> x.language === selectedLanguage)
    let language = isUser ? "en" : LanguageObj.code;

    try {
      const data = await translateService.translate(text, language);
      const translations = data?.data?.data.translations;
      return decoder.decode(translations[0].translatedText);
    } catch (e) {
      console.error(e);
      return text; // Return the original text in case of an error
    }
  }
}

export default jsUtils;
