import React, { forwardRef, useEffect , useState} from "react";
import { createUseStyles } from "react-jss";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import Language from "../../Language";

const LanguageLogo = ({selectedLanguage}) => {
   
    const [isMouseOver, setIsMouseOver] = useState(false)
    const [Image, setImage] = useState(null)
    const [Title, setTitle] = useState(false)

    const style = useStyle({ isMouseOver })

    useEffect(()=>{
        debugger
        let LanguageObj = Language.find(x=> x.language === selectedLanguage)
        setImage(LanguageObj.flag);
        setTitle(LanguageObj.title);

    },[selectedLanguage])

    return (
        <>
            <div className={style.brainButtonContainer} onMouseOver={() => { setIsMouseOver(true) }} onMouseOut={() => { setIsMouseOver(false) }}  >
                <img src={Image} className={style.brainButtonImage} />
            </div>
            <div className={style.overViewContainer} onClick={() => { }} >

                <div className={style.overViewInnerContainer} >
                  <text style={{ fontSize: '14px', color: 'black', fontWeight: '400' }}>{Title}</text>
                </div>
            </div>
        </>
    );
};


const useStyle = createUseStyles({
    brainButtonContainer: {
        // background: '#FFFFFF30',
        // padding: '1%',
        borderRadius: '100%',
        top: '55px',
        // left: '1.5vw',
        position: 'absolute',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'space-between',
        // zIndex: 1000,
        cursor: 'pointer',
        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        // webkitBoxShadow: "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
        // mozBoxShadow: "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
        // boxShadow: "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
        marginLeft: "12px",
        marginTop: "10px",
        zIndex : 9


    },
    brainButtonImage: {
        width: '45px',
        height: '45px',
        objectFit: 'contain',
        alignSelf: 'center'
    },
    overViewContainer: {
        width: '20vh',
        height: '7vh',
        background: '#FFFFFF30',
        borderRadius: 100,
        top: '68px',
        left: (props) => props.isMouseOver ? '1.5vw' : '-50vw',
        position: 'absolute',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        transition: "left 0.3s ease-in-out",
        flexDirection: 'row',
    },
    overViewInnerContainer: {
        width: '15vh',
        height: '6.5vh',
        background: 'transparent',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "left 0.5s ease-in-out",
        flexDirection: 'column',
        borderRadius: 100,

    }
})

export default forwardRef(LanguageLogo);
