import React, { useState, useRef, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Language from "../../Language"

const CustomKeyboard = ({ text, setText, selectedLanguage }) => {
  const keyboard = useRef(null);
  const [layoutName, setLayoutName] = useState("default");
  const [layout, setLayout] = useState(null)

  const onChangeInput = (value) => {
    setText(value);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
  };

  useEffect(()=>{
    let layoutObj = Language.find((x) => x.language === selectedLanguage)
    setLayout(layoutObj.keyboard)
  },[selectedLanguage])
  
  return (
    <div>
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layoutName={layoutName}
        onChange={onChangeInput}
        onKeyPress={onKeyPress}
        onRender={() => console.log("Rendered")}
        layout={layout}
      />
      
    </div>
  );
};

export default CustomKeyboard;
