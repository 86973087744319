import ic_plus from '../../assets/icons/ic_plus.png'
import ic_checked from '../../assets/icons/ic_checked.png'
import ic_unchecked from '../../assets/icons/ic_unchecked.png'
import "../../assets/styles/style.css"
import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { PolicyInnerDetailsModal } from '../chatModule'
import { Modal } from 'react-bootstrap';

const englishContent ={
    heading : "First and Foremost",
    title1 : "This will be similar to a phone conversation where we may converse. If that's fine with you, please grant us access to your microphone when we ask. Your internet connection speed can have a significant influence on call quality; if you are experiencing poor quality, we'd appreciate it if you could do a speed test. When you're in a noisy area or there are other people talking around you, it might be difficult for me to hear you. Please call me from a quiet location and we'll keep this one-on-one for now. I am here to help you improve your mental health and wellbeing. Everything will be kept out of the public eye.",
    title2 : "WHAT AND WHY ARE WE COLLECTING THIS INFORMATION?",
    title3 : " We're recording your chat in order to diagnose any mental illnesses you could be experiencing. This information will also assist me in improving my accuracy in replying to users. This data is anonymous as soon as it is obtained. We do not disclose or transfer this information to third parties. It's only used while you're engaging with the Digital Person to provide you the greatest possible experience. All conversation data collected is anonymized and complies with current privacy practices and regulations. We take your privacy very seriously.",
    title4 : "If you’re happy to proceed on this basis, please confirm your acceptance of our Privacy Policy:",
    title5: "ACCEPT END USER AGREEMENT ALLOW ACCESS TO MICROPHONE AND USAGE DATA.",
    title6: "I'D LIKE TO HEAR YOUR VOICE SO THAT I CAN DO MY BEST WORK.",
    button : "LET'S GO!",
}
const frenchContent ={
    heading : "Tout d'abord",
    title1 : "Cela ressemblera à une conversation téléphonique où nous pourrions converser. Si cela vous convient, veuillez nous accorder l'accès à votre microphone lorsque nous le demandons. La vitesse de votre connexion Internet peut avoir une influence significative sur la qualité des appels ; si vous constatez une mauvaise qualité, nous apprécierions que vous fassiez un test de vitesse. Lorsque vous êtes dans une zone bruyante ou que d'autres personnes parlent autour de vous, il peut être difficile pour moi de vous entendre. S'il vous plaît, appelez-moi depuis un endroit calme et nous garderons cela en tête-à-tête pour le moment. Je suis là pour vous aider à améliorer votre santé mentale et votre bien-être. Tout sera tenu hors de la vue du public.",
    title2 : "QUOI ET POURQUOI COLLECTONS-NOUS CES INFORMATIONS ?",
    title3 : "Nous enregistrons votre conversation afin de diagnostiquer toute maladie mentale dont vous pourriez souffrir. Ces informations m'aideront également à améliorer la précision de mes réponses aux utilisateurs. Ces données sont anonymes dès leur obtention. Nous ne divulguons ni ne transférons ces informations à des tiers. Il n'est utilisé que lorsque vous interagissez avec la personne numérique afin de vous offrir la meilleure expérience possible. Toutes les données de conversation collectées sont anonymisées et sont conformes aux pratiques et réglementations en vigueur en matière de confidentialité. Nous prenons votre vie privée très au sérieux.",
    title4 : "Si vous acceptez de procéder sur cette base, veuillez confirmer votre acceptation de notre politique de confidentialité :",
    title5: "ACCEPTER L'ACCORD UTILISATEUR FINAL AUTORISER L'ACCÈS AU MICROPHONE ET AUX DONNÉES D'UTILISATION.",
    title6: "J'aimerais entendre votre voix pour pouvoir faire mon meilleur travail.",
    button : "ALLONS-Y!",
}
const spanishContent ={
    heading : "Primero y ante todo",
    title1 : "Esto será similar a una conversación telefónica donde podremos conversar. Si le parece bien, concédanos acceso a su micrófono cuando se lo solicitemos. La velocidad de su conexión a Internet puede tener una influencia significativa en la calidad de la llamada; Si experimenta mala calidad, le agradeceríamos que pudiera realizar una prueba de velocidad. Cuando estás en un área ruidosa o hay otras personas hablando a tu alrededor, puede resultarme difícil escucharte. Llámame desde un lugar tranquilo y mantendremos esto uno a uno por ahora. Estoy aquí para ayudarte a mejorar tu salud mental y tu bienestar. Todo se mantendrá fuera del ojo público.",
    title2 : "¿QUÉ Y POR QUÉ RECOPILAMOS ESTA INFORMACIÓN?",
    title3 : "Estamos grabando tu chat para diagnosticar cualquier enfermedad mental que puedas estar experimentando. Esta información también me ayudará a mejorar mi precisión al responder a los usuarios. Estos datos son anónimos desde el momento en que se obtienen. No revelamos ni transferimos esta información a terceros. Solo se utiliza mientras interactúa con la Persona Digital para brindarle la mejor experiencia posible. Todos los datos de conversación recopilados son anónimos y cumplen con las prácticas y regulaciones de privacidad vigentes. Nos tomamos muy en serio su privacidad.",
    title4 : "Si está dispuesto a continuar sobre esta base, confirme su aceptación de nuestra Política de privacidad:",
    title5: "ACEPTAR EL ACUERDO DE USUARIO FINAL PERMITIR EL ACCESO AL MICRÓFONO Y A LOS DATOS DE USO.",
    title6: "ME GUSTARÍA ESCUCHAR TU VOZ PARA PODER HACER MI MEJOR TRABAJO.",
    button : "¡VAMOS!",
}

const arabicContent ={
    heading : "اولا في المقام الاول اولا قبل كل شي",
    title1 : "سيكون هذا مشابهًا لمحادثة هاتفية حيث يمكننا التحدث. إذا كان هذا موافقًا عليك، فيرجى منحنا حق الوصول إلى الميكروفون الخاص بك عندما نطلب ذلك. يمكن أن يكون لسرعة اتصالك بالإنترنت تأثير كبير على جودة المكالمة؛ إذا كنت تعاني من ضعف الجودة، فنحن نقدر لك إجراء اختبار السرعة. عندما تكون في منطقة صاخبة أو عندما يكون هناك أشخاص آخرون يتحدثون حولك، قد يكون من الصعب علي سماعك. من فضلك اتصل بي من مكان هادئ وسنحتفظ بهذا على انفراد في الوقت الحالي. أنا هنا لمساعدتك على تحسين صحتك العقلية ورفاهيتك. كل شيء سيبقى بعيدا عن أعين الناس",
    title2 : "ماذا ولماذا نجمع هذه المعلومات؟",
    title3 : "نقوم بتسجيل الدردشة الخاصة بك من أجل تشخيص أي أمراض عقلية قد تعاني منها. ستساعدني هذه المعلومات أيضًا في تحسين دقتي في الرد على المستخدمين. هذه البيانات مجهولة المصدر بمجرد الحصول عليها. نحن لا نكشف أو ننقل هذه المعلومات إلى أطراف ثالثة. يتم استخدامه فقط أثناء تعاملك مع الشخص الرقمي لتزويدك بأكبر تجربة ممكنة. جميع بيانات المحادثة التي تم جمعها مجهولة المصدر وتتوافق مع ممارسات ولوائح الخصوصية الحالية. نحن نأخذ خصوصيتك على محمل الجد",
    title4 : "إذا كنت سعيدًا بالمتابعة على هذا الأساس، فيرجى تأكيد موافقتك على سياسة الخصوصية الخاصة بنا:",
    title5: "اقبل اتفاقية المستخدم النهائي للسماح بالوصول إلى الميكروفون وبيانات الاستخدام",
    title6: "أود أن أسمع صوتك حتى أتمكن من القيام بأفضل أعمالي",
    button : "دعنا نذهب!",
}
const PolicyAgreementModal = (props, ref) => {

    let { onPressLetsGo } = props
    const [isChecked, setIsChecked] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);
    const [content, setcontent] = useState(englishContent);
    const policyInnerDetailsModalRef = useRef()
    var users = JSON.parse(localStorage.getItem("userVs"));

    const style = useStyle({ isModalShow, isChecked })

    useImperativeHandle(ref, () => ({
        show: () => {
            setIsModalShow(true)
        },
        hide: () => {
            setIsModalShow(false)
        },
    }));

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    const onPressButton = () => {
        setIsModalShow(false)
        onPressLetsGo()
    }
    useEffect(()=>{

        let language = users.language;

        if(language === "en-US"){
            setcontent(englishContent)
        }
        else if(language === "ar-XA"){
            setcontent(arabicContent)
        }
        else if(language === "es-ES"){
            setcontent(spanishContent)
        }
        else if(language === "fr-FR"){
            setcontent(frenchContent)
        }

    },
    [users])

    return (
        <Modal show={isModalShow} animation={false} size="lg">
            <Modal.Body className={style.ModalBody} id="modalContainer">
                <h3 className={style.headerLabel} id="headerLabel">{content.heading}</h3>
                <h3 className={style.smallHeaderLabel} id="smallHeaderLabel" style={{paddingBottom: "12px"}}>{content.title6}</h3>
                <div className={style.contentsContainer} id="desc">
                    <p className={style.desc} style={{ marginTop: '12px', lineHeight: 1.5 }}>
                    {content.title1}
                    </p>
                    <h3 className={style.smallHeaderLabel} id="smallHeaderLabel">
                    {content.title2}
                    </h3>
                    <p className={style.desc} style={{ marginTop: '2%', lineHeight: 1.5 }}>
                    {content.title3}
                    </p>
                </div>
                <p className="policycondition">{content.title4}</p>
                <div className={style.checkedButtonContainer} id="checkedButtonContainer" onClick={() => { handleOnChange() }} >
                    <img src={isChecked ? ic_checked : ic_unchecked} className={style.checkbox} id="checkbox" />
                    <p className={style.desc} style={{ marginLeft: '3%', color: '#429EFA', fontSize: '1.7vh', lineHeight: 1.5 }}  >
                    {content.title5}
                    </p>
                </div>
                <div className={style.buttonContainer} >
                    <button className={style.button} id="modalbtn" onClick={() => { onPressButton() }}>{content.button}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const useStyle = createUseStyles({
    ModalBody: {
        padding: "30px 40px 15px 40px",
    },
    mainContainer: {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        zIndex: 12000,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "top 0.5s ease-in-out",
        top: (props) => props.isModalShow ? 0 : '-100%',

    },
    modalContainer: {
        width: '60%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    modalContentsMainContainer: {
        width: '80%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },

    headerLabel: {
        // textAlign: 'center',
        width: '100%',
        color: '#429EFA',
        fontSize: '4vh',
        margin: 0
    },

    smallHeaderLabel: {
        width: '100%',
        color: '#2A2A2A',
        fontWeight: '500',
        fontSize: '2vh',
        margin: 0,
        marginTop: '1%'

    },
    desc: {
        width: '100%',
        maxWidth: '100%',
        // textAlign: 'center',
        color: 'black',
        margin: 0,
        fontWeight: 'normal',
        fontSize: '1.5vh',
        flexShrink: 1,
        color: '#2A2A2A',

    },
    buttonContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        pointerEvents: (props) => props.isChecked ? 'auto' : 'none',

    },
    checkedButtonContainer: {
        width: '100%',
        justifyContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        padding: "15px 12px 16px 0px"
    },
    button: {
        margin: '2%',
        color: 'white',
        // backgroundColor: '#429EFA',
        backgroundColor: (props) => props.isChecked ? '#429EFA' : '#00000050',
        borderColor: 'transparent',
        padding: '1%',
        borderRadius: 100,
        paddingLeft: '8%',
        paddingRight: '8%',
        cursor: 'pointer'

    },
    contentsContainer: {
        // flex: 1,
        // display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        // alignSelf: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        // display: 'flex',
        flexDirection: 'column',
        // overflowY: 'scroll',
        // overflowX: 'hidden',
        scrollBehavior: 'smooth',
        // whiteSpace: 'nowrap',
        height: "230px",
        overflowX: 'hidden',
        overflowY: 'scroll',
        border: "1px solid #cfcfcf",
        margin: 0,
        marginTop: '1%',
        '&::-webkit-scrollbar': {
            width: '1vh'
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
            'box-shadow': 'inset 0 0 1vh #00000020',
            'border-radius': '10px',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            'background': '#00000020',
            'border-radius': '10px'
        },


    },
    clickableOptionContainer: {
        width: '100%',
        height: '10%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '1%',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'

    },
    questionLabel: {
        width: '60%',
        fontSize: '1.8vh',
        fontWeight: 'normal',
        padding: 0,
        margin: 0,
        alignSelf: 'center',
        color: '#429EFA',


    },
    questionIcon: {
        width: '1.5vh',
        height: '1.5vh',
        objectFit: 'contain',
        objectPosition: 'center',
        alignSelf: 'center',
    },
    checkbox: {
        width: '3vh',
        height: '3vh',
        borderRadius: 100
    }
})

export default forwardRef(PolicyAgreementModal);
