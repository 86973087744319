import React from "react";
// import PropTypes from "prop-types";
// const Nolayout = ({ children, noNavbar, noFooter }) => (
//     <>
//     {children}
//     </>
// );

// Nolayout.propTypes = {

//   noNavbar: PropTypes.bool,
//   noFooter: PropTypes.bool,
// };

// Nolayout.defaultProps = {
//   noNavbar: true,
//   noFooter: true,
// };

// export default Nolayout;


const Nolayout = ({ children }) => (
      {children}
);

export default Nolayout;
