import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/humsafarlogo.png";
import "./style.css";
import axios from "axios";
import { baseURL } from "../../config.js";
import history from "../../history.js";
import Lottie from "react-lottie";
import background from "../../assets/animation/background.json";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import history from "../../history";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const ResetPassword = () => {
  const location = useLocation();
  //   const [values, setvalues] = useState({});
  const [isActive, setisActive] = useState(true);
  const [timeLeft, settimeLeft] = useState("");
  const [values, setValues] = useState({
    email: "",
  });
  const [error, setError] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   let timerOn = true;

//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: background,
//     speed: 1,
//     rendererSettings: {
//       //   preserveAspectRatio: 'xMidYMid slice'
//     },
//   };

  const SendEmail = () => {
    debugger;

    axios
      .post(`${baseURL}reset_password`, values)
      .then(function (response) {
        debugger;

        console.log("I am hit");
        console.log(response.data.message);
        if (response.data.message === "CHECK_YOUR_EMAIL_FOR_RESET_PASSWORD") {
          
          handleOpen();
        } else if (response.data.message === "EMAIL_NOT_FOUND") {

            setError("Email is incorrect or does not exist")
        }
        else if (response.data.message==="RESET_PASSWORD_EXCEED_LIMIT"){
            setError("Reset password exceeds limit.")
        }
        else{
            setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const toLogin = () => {
    // history.push("/Chat");
    history.push("/Login");
  };
  const handleSubmit = () => {
    // console.log("Data is", values);
    SendEmail();
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  
  const btnStyle = {
    padding: "20px 40px 20px 40px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    borderRadius: "16px",
    boxShadow: "rgb(74 79 97 / 25%) 0px 13px 27px -5px, rgb(74 79 97 / 25%) 0px 8px 16px -8px"
  };

  return (
    <section>
      {/* <Lottie options={defaultOptions}
                    // height={'8vh'}
                    // width={'8vh'} 
                    isPaused={false}
            /> */}

      <div className="row m-0">
        <div className="col-12 card-centered">
          <div className="d-flex flex-column align-items-center justify-content-center p-5">
            <div className="card-Logo my-3">
              <img src={Logo} />
            </div>
            <div className="card-body text-center">
              <h5 className="card-title cardText">
                Please enter your email to reset password.
              </h5>

              <ValidatorForm className="signup-form" onSubmit={handleSubmit}>
                <label className="inp">
                  <TextValidator
                    type="text"
                    name="email"
                    className="w-100 loginInput"
                    autoComplete="off"
                    value={values.email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Email is required to Proceed",
                      "Please enter a valid email address.",
                    ]}
                    onChange={onChange}
                    label="Email Address"
                  />
              
                </label>

                <p className="resetMessage error-input">
               {error}
              </p>
                <div className="relative">
                  <button
                    // disabled={loading}
                    typr="submit"
                    className="btn btn-login sendmail"
                  >
                    Send Email
                  </button>
                </div>
              </ValidatorForm>
           

              <div>
              
                <h3 className="new-account" style={btnStyle}>
                  <a onClick={() => history.push("/Login")}>Back to Sign in</a>
                </h3>
             
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            
          <h4 className="card-title emailCard">Check your Email</h4>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            We have send password reset link to
          </Typography>

          <p className="emailText">{values.email}</p>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Check Your Email and Follow Instructions for Password Reset.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Note: Please check your spam and promotion folders, as some of our
            emails end up there for some reason!
          </Typography>

          <div className="relative">
            <a
              href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox"
              target="_blank"
            >
              <button
                // disabled={loading}
                //   typr="submit"
                className="btn btn-login sendmail"
              >
                Open Email App
              </button>
            </a>
          </div>
        </Box>
      </Modal>
    </section>
  );
};
export default ResetPassword;
