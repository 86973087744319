// ------------------Avatar-----------------------
import Adam from "../assets/Avatar/Male/Icon/Adam.png";
import Clyde from "../assets/Avatar/Male/Icon/Clyde.png";
import Daniel from "../assets/Avatar/Male/Icon/Daniel.png";
import Davis from "../assets/Avatar/Male/Icon/Davis.png";
import Eric from "../assets/Avatar/Male/Icon/Eric.png";
import Fin from "../assets/Avatar/Male/Icon/Fin.png";
import Harry from "../assets/Avatar/Male/Icon/Harry.png";
import Jacob from "../assets/Avatar/Male/Icon/Jacob.png";
import Michael from "../assets/Avatar/Male/Icon/Michael.png";
import Peter from "../assets/Avatar/Male/Icon/Peter.png";
import Roger from "../assets/Avatar/Male/Icon/Roger.png";
import Ryan from "../assets/Avatar/Male/Icon/Ryan.png";
import Sam from "../assets/Avatar/Male/Icon/Sam.png";
import Steffan from "../assets/Avatar/Male/Icon/Steffan.png";
import Tony from "../assets/Avatar/Male/Icon/Tony.png";

// ------------------Talking-----------------------

import Talk_Adam from "../assets/Avatar/Male/AdamTalk.mp4";
import Talk_Clyde from "../assets/Avatar/Male/ClydeTalk.mp4";
import Talk_Daniel from "../assets/Avatar/Male/DanielTalk.mp4";
import Talk_Davis from "../assets/Avatar/Male/DavisTalk.mp4";
import Talk_Eric from "../assets/Avatar/Male/EricTalk.mp4";
import Talk_Fin from "../assets/Avatar/Male/FinTalk.mp4";
import Talk_Harry from "../assets/Avatar/Male/HarryTalk.mp4";
import Talk_Jacob from "../assets/Avatar/Male/JacobTalk.mp4";
import Talk_Michael from "../assets/Avatar/Male/MichaelTalk.mp4";
import Talk_Peter from "../assets/Avatar/Male/PeterTalk.mp4";
import Talk_Roger from "../assets/Avatar/Male/RogerTalk.mp4";
import Talk_Ryan from "../assets/Avatar/Male/RyanTalk.mp4";
import Talk_Sam from "../assets/Avatar/Male/SamTalk.mp4";
import Talk_Steffan from "../assets/Avatar/Male/SteffanTalk.mp4";
import Talk_Tony from "../assets/Avatar/Male/TonyTalk.mp4";

// ------------------Blink-----------------------
import Blink_Adam from "../assets/Avatar/Male/AdamBlink.mp4";
import Blink_Clyde from "../assets/Avatar/Male/ClydeBlink.mp4";
import Blink_Daniel from "../assets/Avatar/Male/DanielBlink.mp4";
import Blink_Davis from "../assets/Avatar/Male/DavisBlink.mp4";
import Blink_Eric from "../assets/Avatar/Male/EricBlink.mp4";
import Blink_Fin from "../assets/Avatar/Male/FinBlink.mp4";
import Blink_Harry from "../assets/Avatar/Male/HarryBlink.mp4";
import Blink_Jacob from "../assets/Avatar/Male/JacobBlink.mp4";
import Blink_Michael from "../assets/Avatar/Male/MichaelBlink.mp4";
import Blink_Peter from "../assets/Avatar/Male/PeterBlink.mp4";
import Blink_Roger from "../assets/Avatar/Male/RogerBlink.mp4";
import Blink_Ryan from "../assets/Avatar/Male/RyanBlink.mp4";
import Blink_Sam from "../assets/Avatar/Male/SamBlink.mp4";
import Blink_Steffan from "../assets/Avatar/Male/SteffanBlink.mp4";
import Blink_Tony from "../assets/Avatar/Male/TonyBlink.mp4";

// ------------------Poster-----------------------
import Poster_Adam from "../assets/Avatar/Male/Adam.png";
import Poster_Clyde from "../assets/Avatar/Male/Clyde.png";
import Poster_Daniel from "../assets/Avatar/Male/Daniel.png";
import Poster_Davis from "../assets/Avatar/Male/Davis.png";
import Poster_Eric from "../assets/Avatar/Male/Eric.png";
import Poster_Fin from "../assets/Avatar/Male/Fin.png";
import Poster_Harry from "../assets/Avatar/Male/Harry.png";
import Poster_Jacob from "../assets/Avatar/Male/Jacob.png";
import Poster_Michael from "../assets/Avatar/Male/Michael.png";
import Poster_Peter from "../assets/Avatar/Male/Peter.png";
import Poster_Roger from "../assets/Avatar/Male/Roger.png";
import Poster_Ryan from "../assets/Avatar/Male/Ryan.png";
import Poster_Sam from "../assets/Avatar/Male/Sam.png";
import Poster_Steffan from "../assets/Avatar/Male/Steffan.png";
import Poster_Tony from "../assets/Avatar/Male/Tony.png";


const MaleAvatars = [
    {
        image: Adam,
        title: "1_Adam",
        talk: Talk_Adam,
        blink: Blink_Adam,
        name: "Adam" ,
        poster: Poster_Adam
    },
    {
        image: Clyde,
        title: "1_Clyde",
        talk: Talk_Clyde,
        blink: Blink_Clyde,
        name: "Clyde" ,
        poster: Poster_Clyde
    },
    {
        image: Daniel,
        title: "1_Daniel",
        talk: Talk_Daniel,
        blink: Blink_Daniel,
        name: "Daniel" ,
        poster: Poster_Daniel
    },
    {
        image: Davis,
        title: "1_Davis",
        talk: Talk_Davis,
        blink: Blink_Davis,
        name: "Davis" ,
        poster: Poster_Davis
    },
    {
        image: Eric,
        title: "1_Eric",
        talk: Talk_Eric,
        blink: Blink_Eric,
        name: "Eric" ,
        poster: Poster_Eric
    },
    {
        image: Fin,
        title: "1_Fin",
        talk: Talk_Fin,
        blink: Blink_Fin,
        name: "Fin" ,
        poster: Poster_Fin
    },
    {
        image: Harry,
        title: "1_Harry",
        talk: Talk_Harry,
        blink: Blink_Harry,
        name: "Harry" ,
        poster: Poster_Harry
    },
    {
        image: Jacob,
        title: "1_Jacob",
        talk: Talk_Jacob,
        blink: Blink_Jacob,
        name: "Jacob" ,
        poster: Poster_Jacob
    },
    {
        image: Michael,
        title: "1_Michael",
        talk: Talk_Michael,
        blink: Blink_Michael,
        name: "Michael" ,
        poster: Poster_Michael
    },
    {
        image: Peter,
        title: "1_Peter",
        talk: Talk_Peter,
        blink: Blink_Peter,
        name: "Peter" ,
        poster: Poster_Peter
    },
    {
        image: Roger,
        title: "1_Roger",
        talk: Talk_Roger,
        blink: Blink_Roger,
        name: "Roger" ,
        poster: Poster_Roger
    },
    {
        image: Ryan,
        title: "1_Ryan",
        talk: Talk_Ryan,
        blink: Blink_Ryan,
        name: "Ryan" ,
        poster: Poster_Ryan
    },
    {
        image: Sam,
        title: "1_Sam",
        talk: Talk_Sam,
        blink: Blink_Sam,
        name: "Sam" ,
        poster: Poster_Sam
    },
    {
        image: Steffan,
        title: "1_Steffan",
        talk: Talk_Steffan,
        blink: Blink_Steffan,
        name: "Steffan" ,
        poster: Poster_Steffan
    },
    {
        image: Tony,
        title: "1_Tony",
        talk: Talk_Tony,
        blink: Blink_Tony,
        name: "Tony" ,
        poster: Poster_Tony
    },
]
export default MaleAvatars