import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/humsafarlogo.png"
import "./style.css"
import axios from "axios"
import { baseURL } from "../../config.js"
import background from '../../assets/animation/background.json'
import { Modal } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import history from "../../history.js"


const VerificationModal = ({open,email,password,handleClose}) => {

    const [values, setvalues] = useState({})
    const [isActive, setisActive] = useState(false)
    const [loading, setloading] = useState(false)
    const [timeLeft, settimeLeft] = useState("")

    let timerOn = true;


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: background,
        speed: 1,
        rendererSettings: {
        }
    };


    function timer(remaining) {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;

        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        settimeLeft(m + ':' + s);
        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(function () {
                timer(remaining);
            }, 1000);
            return;
        }

        if (!timerOn) {
            return;
        }

        // Do timeout stuff here
        setisActive(false)
    }

    const SendCode = () => {
        debugger
        setloading(true)
        axios.post(`${baseURL}resend_email_verification`, values)
            .then(function (response) {
                debugger
                setloading(false)
                if (response.data.message === "VERIFICATION_EMAIL_SENT") {
                    setisActive(true)
                    timer(120);
                    // seterror("Email address already exists.")
                }
                else {
               
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        debugger
    
        setvalues({email , password});

    }, [email,password])


    return (
        <Modal show={open} animation={true} size="md" centered className="modal-blured">
            <div className="row m-0">
                <div className="col-12">
                    <div className="modal-close" onClick={()=>handleClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                        </svg>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center p-5">
                        <div className="modal-Logo my-3"><img src={Logo} /></div>
                        <div className="card-body text-center">
                            <h5 className="card-title">Authenticate Your Account</h5>
                            <p className="card-text">We sent a confirmation email to:</p>
                            <h6 className="card-text">{values.email}</h6>
                            <p className="card-text">Check your email and click on the confirmation link to verfiy.</p>
                            <p className="card-text">Note: Please check your spam and promotion folders, as some of our
            emails end up there for some reason!</p>
                            <div className="relative">
                            <button disabled={isActive} onClick={SendCode} className="btn btn-link mt-2" id="resend-btn">Resend email</button>
                            {loading && (
                                        <CircularProgress
                                            size={24}
                                            className="CircularBtn" 
                                        />
                                    )}
                            </div>

                           
                            {isActive &&
                                <div className="resend-counter">Resend email in {timeLeft}</div>
                            }
                              <h3 className="new-account">Back to Login <a onClick={()=>history.push("/Login")}>Sign in</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}



export default VerificationModal;
