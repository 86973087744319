import Login from "./components/sessions/Login"
import Signup from "./components/sessions/Signup"
import AccountVerification from "./components/sessions/AccountVerification"
import Nolayout  from "./Layout/Nolayout";
import ResetPassword from "./components/sessions/ResetPassword"


export default [
    //  {
    //     path: "/",
    //     exact: true,
    //     layout: Nolayout,
    //     component: () => <Redirect to="/landing" />
    //   },
      {
        path: "/Login",
        layout: Nolayout,
        component: Login
      },
      {
        path: "/Signup",
        layout: Nolayout,
        component: Signup
      },
      {
        path: "/AccountVerification",
        layout: Nolayout,
        component: AccountVerification
      },
      {
        path: "/ResetPassword",
        layout: Nolayout,
        component: ResetPassword
      },
    
    ];
    