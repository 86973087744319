// ------------------Avatar-----------------------

import Ana from "../assets/Avatar/Female/Icon/Ana.png";
import Bella from "../assets/Avatar/Female/Icon/Bella.png";
import Elizabeth from "../assets/Avatar/Female/Icon/Elizabeth.png";
import Elli from "../assets/Avatar/Female/Icon/Elli.png";
import Gigi from "../assets/Avatar/Female/Icon/Gigi.png";
import Jane from "../assets/Avatar/Female/Icon/Jane.png";
import Lucy from "../assets/Avatar/Female/Icon/Lucy.png";
import Megan from "../assets/Avatar/Female/Icon/Megan.png";
import Sara from "../assets/Avatar/Female/Icon/Sara.png";
import Jennifer from "../assets/Avatar/Female/Icon/Jennifer.png";

// ------------------Talking-----------------------

import Talk_Ana from "../assets/Avatar/Female/AnaTalk.mp4";
import Talk_Bella from "../assets/Avatar/Female/BellaTalk.mp4";
import Talk_Elizabeth from "../assets/Avatar/Female/ElizabethTalk.mp4";
import Talk_Elli from "../assets/Avatar/Female/ElliTalk.mp4";
import Talk_Gigi from "../assets/Avatar/Female/GigiTalk.mp4";
import Talk_Jane from "../assets/Avatar/Female/JaneTalk.mp4";
import Talk_Lucy from "../assets/Avatar/Female/LucyTalk.mp4";
import Talk_Megan from "../assets/Avatar/Female/MeganTalk.mp4";
import Talk_Sara from "../assets/Avatar/Female/SaraTalk.mp4";
import Talk_Jennifer from "../assets/Avatar/Female/JenniferTalk.mp4";

// ------------------Blink-----------------------

import Blink_Ana from "../assets/Avatar/Female/AnaBlink.mp4";
import Blink_Bella from "../assets/Avatar/Female/BellaBlink.mp4";
import Blink_Elizabeth from "../assets/Avatar/Female/ElizabethBlink.mp4";
import Blink_Elli from "../assets/Avatar/Female/ElliBlink.mp4";
import Blink_Gigi from "../assets/Avatar/Female/GigiBlink.mp4";
import Blink_Jane from "../assets/Avatar/Female/JaneBlink.mp4";
import Blink_Lucy from "../assets/Avatar/Female/LucyBlink.mp4";
import Blink_Megan from "../assets/Avatar/Female/MeganBlink.mp4";
import Blink_Sara from "../assets/Avatar/Female/SaraBlink.mp4";
import Blink_Jennifer from "../assets/Avatar/Female/JenniferBlink.mp4";

// ------------------Poster-----------------------

import Poster_Ana from "../assets/Avatar/Female/Ana.png";
import Poster_Bella from "../assets/Avatar/Female/Bella.png";
import Poster_Elizabeth from "../assets/Avatar/Female/Elizabeth.png";
import Poster_Elli from "../assets/Avatar/Female/Elli.png";
import Poster_Gigi from "../assets/Avatar/Female/Gigi.png";
import Poster_Jane from "../assets/Avatar/Female/Jane.png";
import Poster_Lucy from "../assets/Avatar/Female/Lucy.png";
import Poster_Megan from "../assets/Avatar/Female/Megan.png";
import Poster_Sara from "../assets/Avatar/Female/Sara.png";
import Poster_Jennifer from "../assets/Avatar/Female/Jennifer.png";

const FemaleAvatars = [
    {
        image: Ana,
        title: "0_Ana",
        talk: Talk_Ana,
        blink: Blink_Ana,
        name: "Ana" ,
        poster: Poster_Ana
    },
    {
        image: Bella,
        title: "0_Bella",
        talk: Talk_Bella,
        blink: Blink_Bella,
        name: "Bella" ,
        poster: Poster_Bella
    },
    {
        image: Elizabeth,
        title: "0_Elizabeth",
        talk: Talk_Elizabeth,
        blink: Blink_Elizabeth,
        name: "Elizabeth" ,
        poster: Poster_Elizabeth
    },
    {
        image: Elli,
        title: "0_Elli",
        talk: Talk_Elli,
        blink: Blink_Elli,
        name: "Elli" ,
        poster: Poster_Elli
    },
    {
        image: Gigi,
        title: "0_Gigi",
        talk: Talk_Gigi,
        blink: Blink_Gigi,
        name: "Gigi" ,
        poster: Poster_Gigi
    },
    {
        image: Jane,
        title: "0_Jane",
        talk: Talk_Jane,
        blink: Blink_Jane,
        name: "Jane" ,
        poster: Poster_Jane
    },
    {
        image: Lucy,
        title: "0_Lucy",
        talk: Talk_Lucy,
        blink: Blink_Lucy,
        name: "Lucy" ,
        poster: Poster_Lucy
    },
    {
        image: Megan,
        title: "0_Megan",
        talk: Talk_Megan,
        blink: Blink_Megan,
        name: "Megan" ,
        poster: Poster_Megan
    },
    {
        image: Sara,
        title: "0_Sara",
        talk: Talk_Sara,
        blink: Blink_Sara,
        name: "Sara" ,
        poster: Poster_Sara
    },
    {
        image: Jennifer,
        title: "0_Jennifer",
        talk: Talk_Jennifer,
        blink: Blink_Jennifer,
        name: "Jennifer" ,
        poster: Poster_Jennifer
    },
]

export default FemaleAvatars;