import React, { forwardRef } from "react";
import { createUseStyles } from "react-jss";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import MaleAvatars from "../../Avatar/Male";
import FemaleAvatars from "../../Avatar/Female";
import Divider from '@mui/material/Divider';

const AvatarModal = (props, ref) => {
    let { openAvatar, setopenAvatar, selectedAvatar, setselectedAvatar } = props;
    const style = useStyle({ openAvatar });

    const handleSelected = (value) => {
        setselectedAvatar(value);
    };

    return (
        <Dialog fullWidth={true} maxWidth={"md"} open={openAvatar} onClose={() => setopenAvatar(false)} className="avatar-dialog">
            <DialogTitle>Select Avatar</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{paddingBottom : "12px"}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <p>Male</p>
                    </Grid>
                    {MaleAvatars.map((avatar, index) => (
                        <Grid key={index} item xs={6} sm={2} md={2}>
                            <div className="avatar-container">
                                <div
                                    className={selectedAvatar === avatar.title ? "selected-avatar avatar-items" : "avatar-items"}
                                >
                                    <Avatar
                                        alt={avatar.title}
                                        src={avatar.image}
                                        sx={{ width: 80, height: 80 }}
                                        onClick={() => handleSelected(avatar.title)}
                                    />
                                </div>
                                <span>{avatar.name}</span>
                            </div>

                        </Grid>
                    ))}
                </Grid>
                {/* <Divider /> */}
                <Grid container spacing={2} style={{paddingTop : "12px"}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <p>Female</p>
                    </Grid>

                    {FemaleAvatars.map((avatar, index) => (
                        <Grid key={index} item xs={6} sm={2} md={2}>
                            <div className="avatar-container">
                            <div
                                className={selectedAvatar === avatar.title ? "selected-avatar avatar-items" : "avatar-items"}
                            >
                                <Avatar
                                    alt={avatar.title}
                                    src={avatar.image}
                                    sx={{ width: 80, height: 80 }}
                                    onClick={() => handleSelected(avatar.title)}
                                />
                            </div>
                            <span>{avatar.name}</span>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setopenAvatar(false)}>Done</Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyle = createUseStyles({
    // Your styles here
});

export default forwardRef(AvatarModal);
