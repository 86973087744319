// import { useSpeechRecognition } from 'react-speech-kit';
// import ic_send from '../assets/icons/ic_send.png'
import ic_send_disable from '../../assets/icons/ic_send_disable.svg'
import ic_send from '../../assets/icons/ic_send.svg'
import ic_mic from '../../assets/icons/ic_mic.png'

import { Input } from "antd";
import React, { useEffect, useRef, memo } from "react";
import { createUseStyles } from "react-jss";

const InputBox = ({ onPressSendButton, isInputEnable,text,setText}) => {

    const style = useStyle({isInputEnable})
    const inputRef = useRef()

    useEffect(() => {
        if (isInputEnable)
            inputRef.current.focus()
    }, [isInputEnable])

    const sendInputMessage = (msg) => {
        if (msg !== '') {
            setText('')
            onPressSendButton(msg)
        }
    }
    const onChangeText = (e) => {
        setText(e.target.value)
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendInputMessage(e.target.value)
        }
    }

    return (

        <div className={style.inputBox} id="inputBox" >
            <Input
                ref={inputRef}
                disabled={!isInputEnable}
                value={text}
                className={style.inputFiled}
                placeholder="Type and talk"
                onChange={onChangeText}
                onKeyDown={handleKeyDown} 
                maxlength="256"
                />
            <img src={isInputEnable ? ic_send : ic_send_disable} id="sendButtonn" className={style.sendButton} onClick={() => {
                sendInputMessage(text)
            }} />

        </div>
    );
}


const useStyle = createUseStyles({
    inputBox: {
        height: "6%",
        marginTop: 0,
        padding : "22px",
        // backgroundColor: 'white',
        backgroundColor: (props) => props.isInputEnable ? 'white' : 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 100,
        pointerEvents: (props) => props.isInputEnable ? 'auto' : 'none',
        webkitBoxShadow: "2px 4px 4px #9E9E9E",
        mozBoxShadow: "2px 4px 4px #9E9E9E",
        boxShadow: "2px 4px 4px #9E9E9E",

    },
    inputFiled: {
        width: "100%",
        // height: "100%",
        // fontSize: '2vw',
        // border: 'none',
        borderColor: 'transparent',
        // borderRadius: '100px',
        padding: '22px',
        marginLeft:0,
        outline: 'none',
        background: 'transparent',

        '&:hover': {
        },
        '&:active': {
            borderColor: 'white',
            borderWidth: 0
        },
        '&::-webkit-input-placeholder': { /* Edge */
            color: '#ffff'
        },
        '&::-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: '#A3A3A3'
        },
        '&::placeholder': {
            color: '#A3A3A3'
        }
    },
    sendButton: {
        width: '30px',
        height: '30px',
        margin: '3px',
        padding: '2px',
        cursor: 'pointer',
    },
    micContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent'
    },
    micButtonCotainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        padding: '1%',
        // backgroundColor: (props) => props.isRecording ? 'pink' : 'trasnparent',
        borderRadius: 200,
        marginRight: '2%',

    },
    micButton: {
        width: '2vh',
        height: '4vh',
        marginRight: '2vh',
        marginLeft: '2vh',
        alignSelf: 'center',
        border: 0,
        background: 'transparent',
        objectFit: 'contain',
        objectPosition: 'center',

    }

})

export default memo(InputBox);
