import happy from "../assets/emotion/smile.png";
import sad from "../assets/emotion/sad.png";
import fear from "../assets/emotion/fear.png";
import angry from "../assets/emotion/angry.png";
import neutral from "../assets/emotion/neutral.png";
import surprise from "../assets/emotion/surprise.png";
import disgusted from "../assets/emotion/disgusted.png";

const emotionList = [
    {
        title : "neutral",
        icon : neutral
    },
    {
        title : "happy",
        icon : happy
    },
    {
        title : "sad",
        icon : sad
    },
    {
        title : "angry",
        icon : angry
    },
    {
        title : "disgusted",
        icon : disgusted
    },
    {
        title : "surprised",
        icon : surprise
    },
    {
        title : "fearful",
        icon : fear
    },
]

export default emotionList;