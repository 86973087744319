import React, { forwardRef, useEffect , useState} from "react";
import emotionList from "../../Emotions";

const Emotion = ({emotions}) => {
   
    const [icon, setIcon] = useState(null)

    useEffect(()=>{
        
        let data = emotionList.find(x => x.title === emotions)
        if(data){
            setIcon(data.icon)
        }

    },[emotions])

    return (
        <div className="emotion">
          <img src={icon}/>
          <span className="text">Your mood is <span className="title">{emotions}</span> right now.</span>
        </div>
    );
};

export default forwardRef(Emotion);
