import React, { forwardRef } from "react";
import { createUseStyles } from "react-jss";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import MaleAvatars from "../../Avatar/Male";
import FemaleAvatars from "../../Avatar/Female";
import LanguageList from "../../Language";
import Divider from '@mui/material/Divider';
import CustomKeyboard from "../Keyboard";
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import DragLogo from "../Keyboard/drag.png";

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const KeyboardModal = (props, ref) => {

    let { openKeyboard, setopenKeyboard, chatModule ,text, setText, selectedLanguage } = props;

    const handleDrag = (e, ui) => {
      // Handle drag event if needed
    };

    return (
      <Draggable
      handle=".handle"
      defaultPosition={{ x: 5, y: 70 }}
      position={null}
      grid={[1, 1]}
      scale={1}
      onStart={() => console.log("Start dragging")}
      onStop={() => console.log("Stop dragging")}
      onDrag={handleDrag}
    >
       <div className="draggable-container">
        <div className="handle">
          <img src={DragLogo}></img>
        </div>
        <div className="content">
        <CustomKeyboard text={text} setText={setText} selectedLanguage={selectedLanguage}/>

        </div>
      </div>
        </Draggable>

    );
};

const useStyle = createUseStyles({
    // Your styles here
});

export default forwardRef(KeyboardModal);
