import en from "../assets/CountryIcon/en.png";
import ar from "../assets/CountryIcon/ar.png";
import es from "../assets/CountryIcon/es.png";
import fr from "../assets/CountryIcon/fr.png";
import pk from "../assets/CountryIcon/pak.png";

//Keyboard Layouts

import arabic from "../components/Keyboard/layout/arabic";
import english from "../components/Keyboard/layout/english";
import spanish from "../components/Keyboard/layout/spanish";
import french from "../components/Keyboard/layout/french";
import urdu from "../components/Keyboard/layout/urdu";
import sindhi from "../components/Keyboard/layout/sindhi";

const Language = [
    {
        title : "English",
        code : "en",
        flag : en,
        language : "en-US", 
        female : "en-US-Wavenet-C",
        malw : "en-US-Wavenet-A",
        keyboard : english.layout,
        hasRegion : false,
        functions : "Both text and voice supported"
    },
    {
        title : "Français",
        code : "fr",
        flag : fr,
        language : "fr-FR",
        female : "fr-FR-Wavenet-A",
        male : "fr-FR-Wavenet-D",
        keyboard: french.layout,
        hasRegion : false,
        functions : "Texte et voix pris en charge",
    },
    {
        title : "Español",
        code : "es",
        flag : es,
        language : "es-ES",
        male: "es-ES-Wavenet-B",
        female : "es-ES-Wavenet-C",
        keyboard: spanish.layout,
        hasRegion : false,
        functions : "Admite texto y voz",
    },
    {
        title : "العربية",
        code : "ar",
        flag : ar,
        language : "ar-XA",
        female : "ar-XA-Wavenet-A",
        male : "ar-XA-Wavenet-B",
        keyboard: arabic.layout,
        hasRegion : false,
        functions : "كل من النص والصوت معتمد"
    },
    {
        title : "اردو",
        code : "ur",
        flag : pk,
        language : "ur",
        female : "ar-XA-Wavenet-A",
        male : "ar-XA-Wavenet-B",
        keyboard: urdu.layout,
        hasRegion : false,
        functions : "صرف ٹیکسٹ سپورٹ"
    },
    {
        title : "سنڌي",
        code : "sd",
        flag : pk,
        language : "sd",
        female : "ar-XA-Wavenet-A",
        male : "ar-XA-Wavenet-B",
        keyboard: sindhi.layout,
        region: "ur",
        hasRegion : true,
        functions : "صرف متن جي حمايت"
    },
    {
        title : "پښتو",
        code : "ps",
        flag : pk,
        language : "ps",
        female : "ar-XA-Wavenet-A",
        male : "ar-XA-Wavenet-B",
        keyboard: urdu.layout,
        region: "ur",
        hasRegion : true,
        functions : "د سرف لیکلو اسانتیا شتون لري"

    },
]

export default Language;