import ic_mic from "../../assets/icons/ic_mic.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { v4 as uuidv4 } from "uuid";
import { Audio, Bars } from "react-loader-spinner";
import useSpeechToText from "react-hook-speech-to-text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import useAuth from "../../Authentication/useAuth";
import "../../assets/styles/style.css";
import axiosInstance from "../../axios.js";
import * as faceapi from "face-api.js";
import AvatarModal from "./AvatarModal"
import LanguageModal from "./LanguageModal"
import LanguageIcon from '@mui/icons-material/Language';
import jsUtils from "../../Utils/jsUtils";
import {
  VideoView,
  ChatList,
  SessionExpireModal,
  CompanyLogo,
  PolicyAgreementModal,
  SessionEndedModal,
  SessionLogoutModal,
  SessionCompletedModal,
} from "../chatModule";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import MicNoneIcon from '@mui/icons-material/MicNone';
import LogoutIcon from '@mui/icons-material/Logout';
import ChatIcon from '@mui/icons-material/Chat';
import MicOffIcon from '@mui/icons-material/MicOff';
import TextToSpeech from "./TextToSpeech"
import LanguageLogo from "./LanguageLogo";
import KeyboardModal from "./KeyboardModal";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import Tooltip from '@mui/material/Tooltip';
import Emotion from "./Emotion";

var startTime = null;
var CHAT_SESSOIN = "";
var MIC_IS_RECOGNIZING = false;
var CHATBOT_IS_SPEAKING = false;
var CHAT_START_TIME = "";

var NAME = "";
var AGE = "";
var GENDER = "";
var MARITAL = "";
var DIAGNOSIS = "";

var CHAT_LIST = [];
var Session = [];
var face_expressionArr = [];

const Main = () => {
  const [isChatOrClear, setIsChatOrClear] = useState(true);
  const [isSpeaking, setisSpeaking] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isMicEnable, setIsMicEnable] = useState(true);
  const style = useStyle({ isChatOrClear, isSessionExpired, isMicEnable });
  const videoViewRef = useRef();
  const chatListRef = useRef();
  const sessionExpireModalRef = useRef();
  const sessionEndedModalRef = useRef();
  const SessionLogoutModalRef = useRef();
  const SessionCompletedModalRef = useRef();
  const policyAgreementModalRef = useRef();
  const { logout, user } = useAuth();
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  var users = JSON.parse(localStorage.getItem("userVs"));

  const [openAvatar, setopenAvatar] = useState(false);
  const [emotion, setEmotion] = useState(false);
  const [selectedAvatar, setselectedAvatar] = useState("1_Adam");
  const [openLanguage, setopenLanguage] = useState(false);
  const [openKeyboard, setopenKeyboard] = useState(false);
  const [selectedLanguage, setselectedLanguage] = useState(users.language);
  const [isSpeech, setisSpeech] = useState(false);
  const [textToSpeak, settextToSpeak] = useState("");
  const [text, setText] = useState('')

  const handleOpenAvatarSettings = (event) => {
    setopenAvatar(true);
  };


  const detectionObj = {
    angry: 0,
    disgusted: 0,
    fearful: 0,
    happy: 0,
    neutral: 0,
    sad: 0,
    surprised: 0,
  };

  // Handling Session Number

  // ------------Facial Expressions------------

  const videoHeight = 480;
  const videoWidth = 640;
  const videoElement = useRef();
  const FacialExpressions = useRef(null);

  const onInputNewMessageCallback = useCallback((newInputMsg) => {
    sendMessageToChatBot(newInputMsg);
  });

  const onPressNewSessionCallback = useCallback(() => {
    sessionExpireModalRef.current.hide();
    SessionLogoutModalRef.current.hide();
    sessionExpire(false);
  });


  const onPressSessionBackCallback = useCallback(() => {
    SessionLogoutModalRef.current.hide();
  });
  const onPressLogoutCallback = useCallback(() => {
    StopCamera_Modal();
    logout();
  });

  const onPressSessionEndCallback = useCallback(() => {
    sessionEndedModalRef.current.hide();
    sessionExpire(false);
  });

  const onPressLetsGoCallback = useCallback(() => {
    Newsession();
  }, []);

  const onSelectTextCallback = useCallback((text) => {
    let msg = {
      button: [],
      isClient: true,
      text_eng: text,
      msgTime: jsUtils.getDateTime(),
    };
    chatListRef.current.addMessage(msg);
    sendMessageToChatBot(text);
  }, []);

  const onSessionEndTriggerCallback = useCallback(() => {
    sendChatListToChatBot();
  }, []);

  const onStopVideoAndVoiceCallback = useCallback(() => {
    stopVideo();
  }, []);

  const clearSession = () => {
    stopVideo();
    startTime = "";
    CHAT_SESSOIN = "";
    CHAT_SESSOIN = uuidv4();
  };

  useEffect(() => {
    chatListRef.current.inputDisable();
    // setIsChatOrClear(true)
    if (window.performance) {
      if (performance.navigation.type == 1) {

      } else {
        // alert( "This page is not reloaded");
      }
    }
    policyAgreementModalRef.current.show();
  }, []);

  const loadModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + "/models";
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]).then(startCamera);
  };

  useEffect(async () => {

    results.map(async (result, index) => {
      if (index === results.length - 1) {
        const text = await jsUtils.translateText(result?.transcript, false, selectedLanguage);
        let msg = {
          button: [],
          isClient: true,
          text_eng: text,
          msgTime: jsUtils.getDateTime(),
        };
        chatListRef.current.addMessage(msg);
        sendMessageToChatBot(result?.transcript);
      }
    });

  }, [results]);

  function startCamera() {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then(function (stream) {
          if (videoElement.current) videoElement.current.srcObject = stream;
          const track = stream.getVideoTracks()[0];
          handleFacialExp();
        })
        .catch(function (error) {
          alert("Please check your device permissions");
          console.log(error);
        });

      if (videoElement.current)
        videoElement.current.onloadeddata = function () {
          if (window.NativeDevice)
            window.NativeDevice.htmlCameraReadyToRecord(true);
        };
    }
  }

  const stopVideoOnly = () =>
    videoElement.current?.srcObject?.getTracks()?.forEach((t) => t.stop());

  const DecimalPlaces = (value) => {
    if (value !== null) {
      var res = Math.round(value.toFixed(4) * 100);
      if (res >= 40 && res <= 100) {
        return true;
      }
      return false;
    } else return false;

  };

  const handleFacialExp = () => {

    const threshold = 0.5;
    
    FacialExpressions.current = setInterval(async () => {
      const detections = await faceapi
        .detectAllFaces(
          videoElement.current,
          new faceapi.TinyFaceDetectorOptions()
        )
        .withFaceLandmarks()
        .withFaceExpressions();

      if (detections.length > 0) {
        var data = detections[0].expressions;

        const threshold = 0.5;

          // Find the emotion with the highest probability
          const predictedMood = Object.keys(data).reduce((prevEmotion, currEmotion) => {
            return data[currEmotion] > data[prevEmotion] ? currEmotion : prevEmotion;
          });

          // Check if the probability of the predicted mood is above the threshold
          if (data[predictedMood] >= threshold) {
            setEmotion(predictedMood)
            console.log(`Predicted mood: ${predictedMood}`);
          } else {
            console.log("No specific mood detected or below threshold.");
          }

        Object.keys(data).map(function (key, index) {
          if (DecimalPlaces(data[key])) {
            detectionObj[key] = parseInt(detectionObj[key]) + 1;
            // const predictedMood = Object.keys(emotions).reduce((prevEmotion, currEmotion) => {
            //   return emotions[currEmotion] > emotions[prevEmotion] ? currEmotion : prevEmotion;
            // });
            // console.log(`Predicted mood:`,data[key]);
            // if (detectionObj[predictedMood] >= threshold) {
            // }
          }
        });
      }
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(FacialExpressions.current);
  };
  const playVideo = () => {
    videoViewRef.current.playVideo();
  };

  const stopVideo = () => {
    videoViewRef.current.stopVideo();
  };

  const toggleChatOrClearChat = () => {
    setIsChatOrClear((isChatOrClear) => !isChatOrClear);
  };

  const sessionExpire = (isExpired) => {
    clearAllSession().then((res) => {
      if (
        typeof res.data.message !== "undefined" &&
        res.data.message === "CONTEXT_CLEARED"
      ) {
        console.log("context clear", res);
        setIsSessionExpired(false);
        clearSession();
        chatListRef.current.clearChat();
        setIsChatOrClear(false);
        chatListRef.current.inputEnable();
        setIsMicEnable(true);
        CHAT_START_TIME = "";
        StopCamera_Modal();

        if (!isExpired) {
          setTimeout(() => {
            setIsChatOrClear(true);
            sendMessageToChatBot(users.user_session);
            //sendMessageToChatBot("Depression CBT Session 2");
          }, 100);

          startCamera();
        }
      }
    });
  };
  const Newsession = () => {
    clearAllSession().then((res) => {
      if (
        typeof res.data.message !== "undefined" &&
        res.data.message === "CONTEXT_CLEARED"
      ) {
        if (users.user_session === "Sessions Ended") {
          SessionCompletedModalRef.current.show();
        }
        else {
          chatListRef.current.clearChat();
          sendMessageToChatBot(users.user_session);
          // sendMessageToChatBot("Depression CBT Session 2");
          loadModels();
        }
      }
      // startCamera()
    });



  };

  const clearAllSession = () => {
    return axiosInstance.post(`/clear_context`, { user_id: user.id });
  };

  const toggleMic = () => {
    stopVideo();
    if (isRecording) {
      stopSpeechToText();
      MIC_IS_RECOGNIZING = false;
    } else {
      startSpeechToText();
      MIC_IS_RECOGNIZING = true;
    }
  };

  const setChatMsgs = async (response) => {

    let buttonList = response.data?.button;
    let messagesList = response.data?.text_eng;
    let LongTexttoSpeak = null;

    if (buttonList.length > 0) {
      let button = buttonList.map(obj => obj.text).join('+');
      const buttontext = await jsUtils.translateText(button, false, selectedLanguage);
      buttonList = buttontext.split('+').map(text => ({ text }));
    }
    if (messagesList.length > 1) {
      let messages = messagesList.map(msg => msg).join('+');
      const text = await jsUtils.translateText(messages, false, selectedLanguage);
      messagesList = text.split("+");
      LongTexttoSpeak = messagesList.map(msg => msg).join(" ");
      console.log("messages", messagesList)
    }

    if (response.data?.text_eng.length > 0) {

      for (let index = 0; index < messagesList.length; index++) {

        chatListRef.current.inputDisable();
        setIsMicEnable(false);

        const text = LongTexttoSpeak ? messagesList[index] : await jsUtils.translateText(messagesList[index], false, selectedLanguage);
        settextToSpeak(LongTexttoSpeak ? LongTexttoSpeak : text)
        setisSpeech(true)

        const msg = {
          button: buttonList,
          isClient: false,
          text_eng: text,
          msgTime: jsUtils.getDateTime(),
        };

        chatListRef.current.addMessage(msg);

        chatListRef.current.scrollUp();
        if (buttonList.length === 0) {
          chatListRef.current.inputEnable();
        }

        setIsMicEnable(true);

      }
    }
  };

  const checkForSaveDetails = (userPreviousMsg, newChatBotData) => {
    if (newChatBotData?.save_user_input !== "") {
      switch (newChatBotData?.save_user_input) {
        case "Name":
          NAME = userPreviousMsg;
          break;
        case "Age":
          AGE = userPreviousMsg;
          break;
        case "Pronoun":
          GENDER = userPreviousMsg;
          break;
        case "Marital":
          MARITAL = userPreviousMsg;
          break;
        case "Diagnosis":
          DIAGNOSIS = newChatBotData?.save_user_disgnosis;
          break;

        default:
          break;
      }
    }
  };

  const sendMessageToChatBot = async (msg) => {

    const text = await jsUtils.translateText(msg, true, selectedLanguage);

    if (CHAT_START_TIME === "") {
      CHAT_START_TIME = jsUtils.getDateTime();
    }

    stopVideo();

    if (jsUtils.checkTimeIsRemaining(startTime)) {
      let userTime = jsUtils.getDateTime();

      let requestBody = {
        user_input: text,
        user_id: user.id,
        id_token: users.id_token,
      };

      try {
        axiosInstance
          .post("/get_bot_response", requestBody)
          .then(function (response) {

            if (
              typeof response.data.message !== "undefined" &&
              response.data.message === "INVALID_ID_TOKEN"
            ) {
              try {
                RefreshToken().then((res) => {
                  if (
                    typeof res.data.message !== "undefined" &&
                    res.data.message === "TOKEN_REFRESHED"
                  ) {
                    users.id_token = res.data.data[0].new_id_token;
                    localStorage.setItem("userVs", JSON.stringify(users));
                    sendMessageToChatBot(text);
                  }
                });
              } catch (e) {
                console.log(e);
              }
            }
            else if (response.data.is_session_ended === true) {

              face_expressionArr.push(detectionObj);

              setTimeout(() => sendChatListToChatBot(), 8000);
              // sendChatListToChatBot();
              setChatMsgs(response);
              CHAT_LIST.push({
                user_time: userTime,
                bot_time: jsUtils.getDateTime(),
                user: text,
                bot: response.data?.text_eng,
              });

              checkForSaveDetails(text, response?.data);

            }
            else {
              setChatMsgs(response);
              CHAT_LIST.push({
                user_time: userTime,
                bot_time: jsUtils.getDateTime(),
                user: text,
                bot: response.data?.text_eng,
              });

              checkForSaveDetails(text, response?.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log("Error : ", error);
      }
    } else {
      sessionExpireModalRef.current.show();
    }
  };

  const sendChatListToChatBot = () => {
    const expressionObject = face_expressionArr[0];

    var max = Math.max.apply(
      null,
      Object.keys(expressionObject).map(function (x) {
        return expressionObject[x];
      })
    );
    var face_expressionMax = Object.keys(expressionObject).filter(function (x) {
      return expressionObject[x] == max;
    })[0];
    let expression;
    if (expressionObject[face_expressionMax] === 0) {
      expression = "neutral";
    } else {
      expression = face_expressionMax;
    }

    //  Ends here
    let data = {
      user_id: user.id,
      id_token: users.id_token,
      language: selectedLanguage,
      // next_session:Session[0],
      conversation: {
        // users.user_session:CHAT_LIST
        // language : selectedLanguage,
      },
      user_details: {
        start_time: CHAT_START_TIME,
        name: NAME,
        age: AGE,
        pronoun: GENDER,
        marital_staus: MARITAL,
        diagnosis: DIAGNOSIS,
      },
    };
    data.conversation[users.user_session] = {
      face_expression: expression,
      chat_list: CHAT_LIST,
    };

    try {

      axiosInstance
        .post(`/send_user_conversation`, data)
        .then(function (response) {

          if (
            typeof response.data.message !== "undefined" &&
            response.data.message === "INVALID_ID_TOKEN"
          ) {
            try {
              RefreshToken().then((res) => {
                if (
                  typeof res.data.message !== "undefined" &&
                  res.data.message === "TOKEN_REFRESHED"
                ) {
                  users.id_token = res.data.data[0].new_id_token;
                  localStorage.setItem("userVs", JSON.stringify(users));
                  sendChatListToChatBot();
                }
              });
            } catch (e) {
              console.log(e);
            }
          } else if (response?.status === 200) {
            sessionExpire(true);
            CHAT_LIST = [];
            sessionEndedModalRef.current.show();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log("goodbye error", error);
    }
  };

  const StopCamera_Modal = () => {
    stopVideoOnly();
    handleReset();
  };

  const RefreshToken = () => {
    return axiosInstance.post(`/user_refresh_token`, {
      refresh_token: user.refresh_token,
    });
  };
  const getChatModule = () => {
    return (<ChatList
      ref={chatListRef}
      chatIsClear={isChatOrClear}
      closechat={toggleChatOrClearChat}
      onInputNewMessage={onInputNewMessageCallback}
      onSelectText={onSelectTextCallback}
      onSessionEndTrigger={onSessionEndTriggerCallback}
      onStopVideoAndVoice={onStopVideoAndVoiceCallback}
      text={text}
      setText={setText}
    />)
  };

  return (
    <div
      className={style.mainContainer}
      id={`${isChatOrClear ? "blurContainer" : ""}`}
    >
      <VideoView ref={videoViewRef} id="myVideo" chatIsClear={isChatOrClear} selectedAvatar={selectedAvatar} isSpeaking={isSpeaking} />

      <div className="col-2">
        <div className="left-main-container">
          <div className="left-btns-container">
            <CompanyLogo />
            <LanguageLogo selectedLanguage={selectedLanguage} />
             {emotion && <Emotion emotions={emotion} />}
            <div className="infoMic">
              <span className="left-text1">
                Powered by
                <a
                  className="left-text2"
                  target="_blank"
                  href="https://dls-global.com"
                >
                  Digital Landscape
                </a>
              </span>

              {!isChatOrClear && (
                <div
                  className={style.micButtonMobile}
                  id="micButtonMobile"
                  onClick={() => {
                    toggleMic();
                  }}
                >
                  {isRecording ? (
                    <Bars
                      heigth="100%"
                      width="100%"
                      color="#429EFA"
                      ariaLabel="loading"
                    />
                  ) : (
                    <img src={ic_mic} className={style.micButtonImage} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9 col-8">
        <div className="center-container">
          <div className={style.chatContainer}>
            {getChatModule()}
          </div>
        </div>
      </div>

      <div className="col-md-1 col-2 main-controls">
        <div className="top-right-controls side-controls">
          <div>
            <Tooltip title="Logout" placement="right" arrow>
              <IconButton aria-label="delete" onClick={() => { SessionLogoutModalRef.current.show() }}>
                <LogoutIcon style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          </div>
          {/* <CustomKeyboard /> */}

          <div>
            <Tooltip title="Avatar" placement="right" arrow>
              <IconButton aria-label="delete" onClick={() => !isSpeaking && handleOpenAvatarSettings()}>
                <SupervisedUserCircleIcon style={{ color: '#429efa' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Language" placement="right" arrow>
              <IconButton aria-label="delete" onClick={() => !isSpeaking && setopenLanguage(true)}>
                <LanguageIcon style={{ color: '#429efa' }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="bottom-right-controls side-controls">
          <div>
            <Tooltip title="On-screen Keyboard" placement="right" arrow>
              <IconButton aria-label="delete" onClick={() => setopenKeyboard(!openKeyboard)}>
                <KeyboardIcon style={{ color: '#429efa' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <IconButton aria-label="delete" onClick={() => isMicEnable && toggleMic()}>
              {isRecording ? <Bars
                heigth="28px"
                width="28"
                color="#429EFA"
                ariaLabel="loading"
                className="bar"
              // style={{width : "42px", height : "42px"}}
              /> :
                isMicEnable ?
                  <MicNoneIcon style={{ color: '#429efa' }} />
                  :
                  <MicOffIcon />
              }
            </IconButton>
          </div>
          <div>
            <IconButton aria-label="delete" onClick={toggleChatOrClearChat}>
              {isChatOrClear ?
                <CloseIcon style={{ color: '#429efa' }} /> :
                <ChatIcon style={{ color: '#429efa' }} />
              }
            </IconButton>
          </div>
        </div>

      </div>

      <SessionExpireModal
        ref={sessionExpireModalRef}
        onPressNewSession={onPressNewSessionCallback}
      />
      <SessionEndedModal
        ref={sessionEndedModalRef}
        onPressNewSession={onPressSessionEndCallback}
        onPressLogout={onPressLogoutCallback}
      />
      <SessionCompletedModal
        ref={SessionCompletedModalRef}
        onPressNewSession={onPressSessionEndCallback}
        onPressLogout={onPressLogoutCallback}
      />
      <SessionLogoutModal
        ref={SessionLogoutModalRef}
        onPressNewSession={onPressNewSessionCallback}
        onPressLogout={onPressLogoutCallback}
        onPressBackSession={onPressSessionBackCallback}
      />
      <PolicyAgreementModal
        ref={policyAgreementModalRef}
        onPressLetsGo={onPressLetsGoCallback}
      />
      <TextToSpeech
        isSpeech={isSpeech}
        setisSpeech={setisSpeech}
        setisSpeaking={setisSpeaking}
        stopVideo={stopVideo}
        playVideo={playVideo}
        textToSpeak={textToSpeak}
        isSpeaking={isSpeaking}
        selectedAvatar={selectedAvatar}
        selectedLanguage={selectedLanguage}
      />
      {!isSpeaking && <AvatarModal openAvatar={openAvatar} setopenAvatar={setopenAvatar} selectedAvatar={selectedAvatar} setselectedAvatar={setselectedAvatar} />}
      {!isSpeaking && <LanguageModal openLanguage={openLanguage} setopenLanguage={setopenLanguage} selectedLanguage={selectedLanguage} setselectedLanguage={setselectedLanguage} />}
      {openKeyboard && <KeyboardModal
        openKeyboard={openKeyboard}
        setopenKeyboard={setopenKeyboard}
        chatModule={getChatModule()}
        text={text}
        setText={setText}
        selectedLanguage={selectedLanguage}
      />}
      {/* --------------------Facial Expressions Video Div---------------------------- */}

      {/* {audioURL && <audio controls src={audioURL} />} */}
      {/* {initial &&  */}
      <video
        ref={videoElement}
        autoPlay
        muted
        height={videoHeight}
        width={videoWidth}
        style={{ opacity: 0 }}
      ></video>
      {/* } */}
    </div>
  );
};

const useStyle = createUseStyles({
  mainContainer: {
    display: "flex",
    top: "0%",
    left: "0%",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "absolute",
  },
  chatContainer: {
    // minWidth: '40%',
    // width: '40%',
    padding: "0 20px",
    width: (props) => (props.isChatOrClear ? "100%" : "0%"),
    height: "100vh",
    opacity: (props) => (props.isChatOrClear ? 1 : 0),
    display: "flex",
    justifyContent: "end",
  },
  rightBtnContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "15%",
    paddingRight: "10px",
  },
  rightTopBtnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  rightBottomBtnContainer: {
    marginBottom: "30px",
    marginLeft: "10px",
  },
  logoutButtonContainer: {
    marginTop: "10px",
    background: "white",
    padding: "1.6vh",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  micButtonMobile: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  micButtonContainer: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  brainButtonContainer: {
    background: "white",
    // padding: '1%',
    borderRadius: "100%",
    top: "2%",
    left: "1%",
    position: "absolute",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  chatImgButtonContainer: {
    background: "white",
    padding: "1.8vh",
    marginTop: "5px",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  chatImgButton: {
    width: "3vh",
    height: "3vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  logoutButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  avatarImage: {
    width: "20px",
    height: "20px",
    objectFit: "contain",
    alignSelf: "center",
  },
  micButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  brainButtonImage: {
    width: "6.5vh",
    height: "6.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  chatExpiryScreen: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    zIndex: 12000,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    transition: "top 0.5s ease-in-out",
    top: (props) => (props.isSessionExpired ? 0 : "-100%"),
  },
  chatExpiryPopupView: {
    width: "20%",
    height: "30%",
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: "8%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  sessionModalHeader: {
    textAlign: "center",
  },
  sessionModalDesc: {
    textAlign: "center",
    color: "#00000050",
  },
  sessionModalButton: {
    width: "12vh",
    height: "4vh",
    color: "white",
    alignSelf: "center",
    backgroundColor: "black",
    borderColor: "transparent",
    borderRadius: 100,
  },
  brainButtonImage: {
    width: "7vh",
    height: "7vh",
    objectFit: "contain",
    alignSelf: "center",
  },
});

export default React.memo(Main);
