import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { createUseStyles } from "react-jss";
import MaleAvatars from "../../Avatar/Male";
import FemaleAvatars from "../../Avatar/Female"; 

const VideoView = (props, ref) => {
    const style = useStyle()
    const videoPlayer = useRef()
    const [isVideoStart, setIsVideoStart] = useState(false)
    const [talkingVideo, settalkingVideo] = useState(null)
    const [blinkingVideo, setblinkingVideo] = useState(null)
    const [poster, setposter] = useState(null)

    let { chatIsClear,selectedAvatar,isSpeaking } = props
    useEffect(() => {

        //Male = 1
        //Female = 0
        let selected = selectedAvatar.split("_");
        let Avatar_Gender = selected[0] === "1" ? MaleAvatars : FemaleAvatars;

        let AvatarObject =  Avatar_Gender.find((x) => x.title === selectedAvatar);

        if(AvatarObject){
            settalkingVideo(AvatarObject.talk)
            setblinkingVideo(AvatarObject.blink)
            setposter(AvatarObject.poster)
        }

    }, [chatIsClear, selectedAvatar])

    useImperativeHandle(ref, () => ({
        playVideo: () => {
            // document.querySelector('video').playbackRate = 1;
            setIsVideoStart(true)
            videoPlayer.current.play()
        },
        stopVideo: () => {
            setIsVideoStart(false)
            videoPlayer.current.play()
        },
    }));

    return (
        <>
            <video ref={videoPlayer} loop muted id="videoBox"
                className={` ${chatIsClear ? "videoblur" : ""}`}
                src={isVideoStart ? talkingVideo : blinkingVideo}
                poster={poster}
            ></video>
        </>
    );
}

const useStyle = createUseStyles({
    // video:{
    //     height:'100%'
    // }
})

export default forwardRef(VideoView);
