import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/humsafarlogo.png";
import Google from "../../assets/images/Google.svg";
import faceid from "../../assets/images/faceid.svg";
import useAuth from "../../Authentication/useAuth";
import history from "../../history";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import VerificationModal from "./VerificationModal";
import { signInWithGoogle } from "../../Authentication/firebase";

const Login = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [error, seterror] = useState("");
  
  const [EmailError, setEmailError] = useState("")
  const [PasswordError, setPasswordError] = useState("")

  const [open, setOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const { login } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    seterror("");

    try {
      var response = await login(values.email, values.password);
      debugger;
      // setLoader(false);
      if (response.message === "USER_LOGIN_SUCCESSFUL") {
        history.push("/Chat");
      } else if (response.message === "VERIFY_EMAIL_FIRST") {
        setOpen(true);
      }
    //    else if (
    //     response.message === "INVALID_PASSWORD" ||
    //     response.message === "EMAIL_NOT_FOUND"
    //   ) {
    //     seterror("Email/Password is incorrect");
    //   } 
    else if(response.message === "INVALID_PASSWORD"){
        setPasswordError("Password is incorrect");
    }
    else if(response.message === "EMAIL_NOT_FOUND"){
      setEmailError("Email is incorrect or does not exist");
    }
      
      else if (
        response.message ===
          "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.") {
        seterror("Account Temporarily disabled due to many failed Login attempts");
      }
    } catch (e) {
      console.log(e);
    }

    setLoader(false);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const togglePassword = () => {
    setshowPassword((prev) => !prev);
  };

  return (
    <div className="app-container">
      <div className="app-main">
        <div className="logo-wrapper">
          <img src={Logo}></img>
        </div>
      </div>
      <div className="right-side">
        <div className="login-container">
          <div className="login-area ">
            <div className="main-logo">
              <img src={Logo}></img>
            </div>
            <main className="signup-container">
              <h1 className="heading-primary">
                Sign in<span className="span-blue">.</span>
              </h1>
              <h3 className="new-account">
                New User?{" "}
                <a onClick={() => history.push("/Signup")}>Create an account</a>
              </h3>

              <ValidatorForm className="signup-form" onSubmit={handleSubmit}>
                <label className="inp">
                  <TextValidator
                    type="text"
                    name="email"
                    className="w-100 loginInput"
                    autoComplete="off"
                    value={values.email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Email is required to Proceed",
                      "Please enter a valid email address.",
                    ]}
                    onChange={onChange}
                    label="Email Address"
                  />
                  {/* <span className="input-icon"><i className="fa-solid fa-envelope"></i></span> */}
                </label>
                <span className="error-input" style={{ margin: "0" }}>
                  {EmailError}
                </span>

                <label className="inp">
                  <TextValidator
                    type={!showPassword ? "password" : "text"}
                    name="password"
                    className="w-100 loginInput"
                    id="password"
                    autoComplete="off"
                    onChange={onChange}
                    value={values.password}
                    validators={["required"]}
                    errorMessages={["Password is required to Proceed"]}
                    label="Password"
                  />
                  <span
                    onClick={togglePassword}
                    className="input-icon input-icon-password"
                    data-password
                  >
                    <i
                      className={`fa-solid ${
                        !showPassword ? "fa-eye" : " fa-eye-slash"
                      } `}
                    ></i>
                  </span>
                </label>
                <span className="error-input" style={{ margin: "0" }}>
                  {PasswordError}
                </span>

                {/* <div className="d-flex align-items-center ">
                  <label className="control control--checkbox mb-0">
                    <span className="caption">Keep me signed in</span>
                    <input type="checkbox" checked />
                    <input type="checkbox" checked />
                    <div className="control__indicator"></div>
                  </label>
                </div> */}

                <span className="error-input" style={{ margin: "0" }}>
                  {error}
                </span>

                <div className="relative">
                  <button
                    disabled={loading}
                    typr="submit"
                    className="btn btn-login"
                  >
                    Sign in
                  </button>

                  {loading && (
                    <CircularProgress size={24} className="CircularBtn" />
                  )}
                </div>

                {/* <p class="or"><span>Or</span></p> */}

                                {/* <div class="social-login">
                                    <button class="social-btn" onClick={signInWithGoogle}>
                                        <img alt="Google" src={Google} />
                                        <p>Sign in with Google</p>
                                    </button>
                                </div> */}
                                {/* <div class="social-login">
                                    <button class="social-btn">
                                        <img alt="FaceId" src={faceid} />
                                        <p>Sign in with Facial ID</p>
                                    </button>
                                </div> */}

<h3 className="new-account">
                Forgot Password?{" "}
                <a onClick={() => history.push("/ResetPassword")}>Reset Password</a>
              </h3>
              </ValidatorForm>
            </main>
          </div>
        </div>
        {open && (
          <VerificationModal
            open={open}
            email={values.email}
            password={values.password}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
};
export default Login;
