import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { baseURL } from '../config.js'
import history from '../history.js';
import jwtDecode from 'jwt-decode'
import jwt from 'jsonwebtoken'

const JWT_SECRET = 'digitallandscape123456'
const JWT_VALIDITY = '7days'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    console.log(decodedToken)
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('vsSession', accessToken)
    } else {
        localStorage.removeItem('vsSession')
    }
}


const reducer = (state, action) => {

    switch (action.type) {

        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
})

export const AuthProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {

        
        const response = await axios.post(`${baseURL}user_login`, {
            email,
            password,
        })
        console.log(response)
       
        if (response.data.message === "USER_LOGIN_SUCCESSFUL") {

            var res = response.data.data[0].user_details;

            const accessToken = jwt.sign({ id: res.id }, JWT_SECRET, {
                expiresIn: JWT_VALIDITY,
            })

            setSession(accessToken)
            const user = {
                id: res.id,
                email: res.email,
                first_name: res.first_name,
                last_name: res.last_name,
                id_token: res.id_token,
                refresh_token: res.refresh_token,
                user_session: res.user_session,
                language : res.language
            }
            
            localStorage.setItem('userVs', JSON.stringify(user));

            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
            
        }
        return response.data
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
        history.push('/Login')
    }

    useEffect(() => {
        ; (async () => {
            
            try {

                const accessToken = window.localStorage.getItem('vsSession')
                if (accessToken) {

                    var usersList = [JSON.parse(localStorage.getItem("userVs"))];
                    const { id } = jwt.verify(accessToken, JWT_SECRET);
                    const user = usersList.find((u) => u.id === id)
            
                    if (user) {
                        // history.push('/dashboard')
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        })
                    }
                    else {
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,

                            },
                        })
                    }
                }
                else {

                    // history.push('/Login')
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,

                        },
                    })
                }
            }
            catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {

        return null
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
