import axios from "axios";
import {baseURL} from './config';

let headers = {};

// if(localStorage.getItem("vsToken"))
// {
//     headers.Authorization = `Bearer ${localStorage.getItem("vsToken")}`;
// }

const axiosInstance = axios.create({
baseURL:baseURL,
headers
});

export default axiosInstance; 
