import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/humsafarlogo.png"
import "./style.css"
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from "axios"
import { baseURL } from "../../config.js"
import history from "../../history.js"
import CircularProgress from "@mui/material/CircularProgress";
import Language from "../../Language"

const Signup = () => {

    const [values, setvalues] = useState({

        user_first_name: "",
        user_last_name: "",
        email: "",
        password: "",
        language: ""
    })

    const [EmailError, setEmailError] = useState("")
    const [PasswordError, setPasswordError] = useState("")
    const [showPassword, setshowPassword] = useState(false)
    const [loading, setLoader] = useState(false);
    const [selectedLanguage, setLanguage] = useState("en-US");

    const handleSubmit = (e) => {
        debugger
        e.preventDefault();
        setLoader(true);

        setEmailError("")
        setPasswordError("")
        if (values.password.length < 8) {
            setPasswordError("Password must be at least 8 characters")
        }
        else {
            
            axios.post(`${baseURL}user_signup`, {...values, language : selectedLanguage})
                .then(function (response) {
                    if (response.data.message === "EMAIL_EXISTS") {
                        setEmailError("Email address already exists.")
                    }
                    else {
                        history.push({
                            pathname: './AccountVerification',
                            state: { email: values.email, password: values.password }
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        // setLoader(false);
        setTimeout(() => setLoader(false), 1000);
    };

    const onChange = (e) => {
        setvalues({ ...values, [e.target.name]: e.target.value });
    };

    const togglePassword = () => {
        setshowPassword(prev => !prev);
    };

    return (
        <div className="app-container">

            <div className="app-main">
                <div className="logo-wrapper">
                    <img src={Logo}></img>

                </div>
            </div>
            <div className="right-side">
                <div className="login-container">

                    <div className="login-area">
                        <div className="main-logo">
                            <img src={Logo}></img>
                        </div>
                        <main className="signup-container">
                            <h1 className="heading-primary">Create an account<span className="span-blue">.</span></h1>
                            <h3 className="new-account">Already an User? <a onClick={() => history.push("/Login")}>Sign in</a></h3>
                            <ValidatorForm className="signup-form" onSubmit={handleSubmit}>

                                <div className="group-input d-flex justify-content-between">
                                    <label className="inp">
                                        <TextValidator
                                            type="text"
                                            name="user_first_name"
                                            //   className="w-100"
                                            autoComplete="off"
                                            value={values.user_first_name}
                                            validators={['required', `matchRegexp:^[a-zA-Z]{1,}(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$`]}
                                            errorMessages={['first name is required.', 'first name is invalid']}
                                            onChange={onChange}
                                            label="First Name"
                                        />
                                    </label>
                                    <label className="inp">
                                        <TextValidator type="text"
                                            autoComplete="off"
                                            name="user_last_name"
                                            value={values.user_last_name}
                                            validators={['required', `matchRegexp:^[a-zA-Z]{1,}(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$`]}
                                            errorMessages={['last name is required.', 'last name is invalid']}
                                            onChange={onChange}
                                            label="Last Name"
                                        />
                                    </label>
                                </div>

                                <label className="inp">
                                    <TextValidator type="text"
                                        autoComplete="off"
                                        className="w-100"
                                        name="email"
                                        value={values.email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['Email is required.', 'Please enter a valid email address.']}
                                        onChange={onChange}
                                        label="Email Address"
                                    />
                                </label>
                                <span className="error-input">{EmailError}</span>

                                <label className="inp">
                                    <TextValidator
                                        type={!showPassword ? "password" : "text"}
                                        className="w-100"
                                        autoComplete="off"
                                        name="password"
                                        value={values.password}
                                        validators={['required']}
                                        errorMessages={['Password is required.']}
                                        onChange={onChange}
                                        label="Password"
                                    />
                                    <span onClick={togglePassword} className="input-icon input-icon-password" data-password><i className={`fa-solid ${!showPassword ? "fa-eye" : " fa-eye-slash"} `}></i></span>
                                </label>
                                <span className="error-input">{PasswordError}</span>
                                <div className="row language-selected">
                                    <h1>Select Language</h1>
                                    { Language.map((x)=>{
                                        return(<div className="col-md-3 col-sm-6">
                                        <span onClick={()=>setLanguage(x.language)} className={selectedLanguage === x.language && "selected"}>
                                            {x.title}
                                        </span>
                                        </div>)
                                    })}
                    
                                </div>
                                <div className="relative">
                                    <button className="btn btn-signUp" disabled={loading}>Sign up</button>
                                    {loading && (
                                        <CircularProgress size={24} className="CircularBtn" />
                                    )}
                                </div>
                            </ValidatorForm>

                        </main>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default Signup